export default [
  '00008b',
  '0000ff',
  '006400',
  '008080',
  '008b8b',
  '00bfff',
  '00ced1',
  '1d89cb',
  '1e90ff',
  '20b2aa',
  '228b22',
  '2e8b57',
  '2f4f4f',
  '32cd32',
  '3cb371',
  '4169e1',
  '4682b4',
  '483d8b',
  '48d1cc',
  '4b0082',
  '556b2f',
  '5f9ea0',
  '66cdaa',
  '6a5acd',
  '6b8e23',
  '7b68ee',
  '808000',
  '8a2be2',
  '8b0000',
  '8b008b',
  '8b4513',
  '9370db',
  '9400d3',
  'b22222',
  'b8860b',
  'ba55d3',
  'bc8f8f',
  'c71585',
  'cd5c5c',
  'd2691e',
  'da70d6',
  'daa520',
  'db7093',
  'dc143c',
  'e9967a',
  'f08080',
  'f4a460',
  'fa8072',
  'ff0000',
  'ff1493',
  'ff6347',
  'ff69b4',
  'ff8c00'
] as const;
